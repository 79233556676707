import React, { useEffect, useState } from "react"

import { getConfirmedUserOtherwiseNavigate } from "../services/auth"
import routes, {navigate} from "../services/routes"

export default () => {

	useEffect(() => {
		// create a new board
		const user = getConfirmedUserOtherwiseNavigate();
		if (user) {
			(async () => {
				const board = await user.addBoard();
				if (board) {
					navigate(routes.board(board.urlId), {replace: true});
				}
			})();
		}
	}, []);

	return <p>Creating a new board...</p>;
};